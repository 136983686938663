/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCq-st6EnTWHnDezwTyhpBUyCy-jrDQ-uI",
  "appId": "1:786410835412:web:0947a3d801af1a62491a41",
  "authDomain": "schooldog-i-cartersville-ga.firebaseapp.com",
  "measurementId": "G-SPVT4TQKVD",
  "messagingSenderId": "786410835412",
  "project": "schooldog-i-cartersville-ga",
  "projectId": "schooldog-i-cartersville-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-cartersville-ga.appspot.com"
}
